"use strict";
let utils = new Utils();
let render = new Render();
let animate = new Animate();
let handlers = new Handlers();

class Main {

  constructor(){}

  init()
  {

    animate.init();
    handlers.init();
    // render.init();

  }


}

var main = new Main();

main.init();
