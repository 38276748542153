"use strict";

class Render {

  constructor(){
    this.pageData = utils.get("./lib/data/pageData.json");
  }

  init()
  {

    this.checkPageData();

  }


  setPageData(data){
    this.pageData = data;
  }

  checkPageData(){
    if(this.pageData){
        this.pageData = JSON.parse(this.pageData);
        console.log(this.pageData);
    }else{
      console.log("sup");
      utils.get("/lib/data/pageData.json");
      setTimeout(()=>{
        this.checkPageData();
      }, 250);
    }
  }


}
