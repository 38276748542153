"use strict";

class Utils {

  constructor(){

  }

  init()
  {

  }

  get(path, callback){
    var request = new XMLHttpRequest();
    let response;

    request.open('GET', path, true);

    request.onload = () =>
    {
      if (request.status >= 200 && request.status < 400) {
        var resp = request.responseText;
        render.setPageData(resp);
        return resp;
      } else {
        return "error";
      }
    };

    request.onerror = () =>
    {
      return "error";
    };

    request.send();
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }



  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }



  closestELPolyfill(){
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function(s)
      {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i,
            el = this;
        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) {};
        } while ((i < 0) && (el = el.parentElement));
          return el;
        };
    }

  }

  stringPrototype(){
    String.prototype.insert = function (index, string) {
      if (index > 0){
        return this.substring(0, index) + string + this.substring(index, this.length);
      }else{
        return string + this;
      }
    };
  }



  formatPrice(number)
  {
   var comma = ',',
       string = Math.max(0, number).toFixed(0),
       length = string.length,
       end = /^\d{4,}$/.test(string) ? length % 3 : 0;
   return (end ? string.slice(0, end) + comma : '') + string.slice(end).replace(/(\d{3})(?=\d)/g, '$1' + comma);
  }






}
