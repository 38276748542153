"use strict";

class Handlers {

  constructor(){

  }

  init()
  {
    this.bindUI();
  }

  bindUI(){
    let homeEl = document.querySelector(".nav__logoContainer");
    let aboutEl = document.querySelector(".nav__about");
    let contactEl = document.querySelector(".nav__contact");
    let hamburgerEl = document.querySelector(".nav__hamburger");
    let mobileNav = document.querySelector(".nav__mobile");

    aboutEl.addEventListener("click", (e)=>{
      e.preventDefault();
      this.gotoPage(e);
    });

    contactEl.addEventListener("click", (e)=>{
      e.preventDefault();
      this.gotoPage(e);
    });

    homeEl.addEventListener("click", (e)=>{
      if( window.location.pathname != "/" ){
        e.preventDefault();
        this.gotoPage(e);
      }
    });

    hamburgerEl.addEventListener("click", ()=>{
      hamburgerEl.classList.toggle("nav__hamburger--is-active");
      mobileNav.classList.toggle("nav__mobile--is-active");
    });

  }

  gotoPage(e){
    let url = e.target.dataset.url;
    document.body.classList.add("fade-out");
    setTimeout(()=>{
      window.location = url;
    }, 2000);
  }


}
